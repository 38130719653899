import NextLink from 'next/link';
import React from 'react';

import config from '@/core/config';
import Box, { BoxProps } from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import MuiLink, { LinkProps } from '@mui/material/Link';
import Typography, { TypographyProps } from '@mui/material/Typography';

// Custom MDX components replacing default HTML tags
const mdxComponents = {
    h1: (props: TypographyProps) => (
        <Typography variant="h1" component="h1" gutterBottom {...props} />
    ),
    h2: (props: TypographyProps) => (
        <Typography variant="h2" component="h2" gutterBottom {...props} />
    ),
    h3: (props: TypographyProps) => (
        <Typography variant="h3" component="h3" gutterBottom {...props} />
    ),
    p: (props: TypographyProps) => (
        <Typography variant="body1" component="p" {...props} />
    ),
    a: (props: LinkProps) => {
        const isBlank = (props.href as string).startsWith('http');
        return (
            <MuiLink
                component={NextLink}
                color="primary"
                target={isBlank ? '_blank' : undefined}
                sx={{
                    textDecoration: 'none',
                    '&:hover': { textDecoration: 'underline' },
                }}
                {...props}
            />
        )
    },
    ul: (props: BoxProps) => (
        <Box component="ul" sx={{ pl: 4, mb: 2 }} {...props} />
    ),
    li: (props: BoxProps) => (
        <Box component="li" sx={{ mb: 1, typography: 'body1' }} {...props} />
    ),
    blockquote: (props: TypographyProps) => (
        <Typography
            component="blockquote"
            sx={{
                borderLeft: '4px solid gray',
                pl: 2,
                color: 'text.secondary',
                fontStyle: 'italic',
                mb: 2,
            }}
            {...props}
        />
    ),
    hr: (props: React.HTMLAttributes<HTMLHRElement>) => (
        <Box py={7}>
            <Divider {...props} />
        </Box>
    ),
    img: (props: HTMLImageElement) => (
        <Box
            component="span"
            sx={{
                display: 'block',
                bgcolor: 'background.paper',
                borderRadius: 4,
                my: 10,
                maxHeight: '70vh',
                overflow: 'auto',
            }}>
            {props.alt && (
                <Box
                    component="span"
                    sx={{
                        display: 'block',
                        position: 'sticky',
                        top: 0,
                        bgcolor: 'background.paper',
                        borderBottom: 1,
                        borderColor: 'divider',
                        p: 3,
                    }}>
                    <Typography component="span" variant="h5" color="text.secondary" sx={{
                        display: 'block',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        textAlign: 'center',
                    }}>
                        {props.alt}
                    </Typography>
                </Box>
            )}
            <Box
                component="span"
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                <Box component="img"
                    src={config.assets.getStatic(props.src)}
                    alt={props.alt}
                    sx={{
                        width: '100%',
                        height: 'auto',
                        display: 'block',
                    }} />
            </Box>
        </Box>
    ),
};

interface ExampleProps {
    Component: React.ElementType;
    filePath?: string;
}
const MdxView = ({ Component, filePath }: ExampleProps) => {

    // const editLink = () => {
    //     if (!filePath) return;
    //     const editUrl = config.github.editContent(filePath);

    //     return (
    //         <Box sx={{
    //             mt: 15,
    //         }}>
    //             <Button
    //                 variant='contained'
    //                 color="inherit"
    //                 size="small"
    //                 sx={{
    //                     opacity: 0.2,
    //                     '&:hover': { opacity: 1 },
    //                 }}
    //                 component="a" href={editUrl} target="_blank" rel="noopener noreferrer">
    //                 Edit this page
    //             </Button>
    //         </Box>
    //     )
    // }

    return (

        <Box sx={{

            /**
             * Apply margin top to all headings, lists and paragraphs
             */
            "&": {
                ...(Object.fromEntries(
                    ["p", "ul", "ol"].flatMap(tag =>
                        Array.from({ length: 6 }, (_, i) => [`${tag} + h${i + 1}`, {
                            mt: 5,
                        }])
                    )
                )),
            },
            // margin between p + p
            "p + p": {
                mt: 3,
            },
        }}>
            <Component components={mdxComponents} />
        </Box>
    )
};

export default MdxView;


